import React, { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useLocation, Link } from 'react-router-dom';
import Parallax from './animations/parallax.js';
import ScrollToTopButton from './animations/scrollToTopButton.js';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';

const NavLink = ({ children, to }) => {
  const [styles, api] = useSpring(() => ({ scale: 1, config: { tension: 150, friction: 10 } }));

  return (
    <animated.div
      onMouseEnter={() => api.start({ scale: 1.1 })}
      onMouseLeave={() => api.start({ scale: 1 })}
      style={styles}
    >
      <Link to={to} className="nav-link" style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    </animated.div>
  );
};

const NavButton = ({ children, onClick }) => {
  const [styles, api] = useSpring(() => ({ scale: 1, config: { tension: 150, friction: 10 } }));

  return (
    <animated.div
      onMouseEnter={() => api.start({ scale: 1.1 })}
      onMouseLeave={() => api.start({ scale: 1 })}
      style={styles}
      onClick={onClick}
    >
      <span className="nav-link">
        {children}
      </span>
    </animated.div>
  );
};

const Navbar = () => {
  const { openLoginModal } = useUI(); 
  const { currentUser, logout } = useAuth(); 

  const handleLogout = async () => {
    try {
      await logout();
      // Gérer ici ce qui se passe après la déconnexion si nécessaire
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };

  const [scrollY, setScrollY] = useState(0);
  const threshold = window.innerHeight * 0.5;
  const navbarStyle = useSpring({
    transform: `translateY(${scrollY > threshold ? '-100%' : '0%'})`, // Remplacez '100' par le seuil de défilement désiré
    opacity: scrollY > threshold ? 0 : 1,
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const baseSommairePath = pathSegments[1];
  const isCoursePage = pathSegments.length > 2; // Vérifie s'il s'agit d'une page de cours spécifique

  const routesWithParallax = ['/', '/formationSaimer', '/formationCroissance', '/formationSecouter']; 
  const showParallax = routesWithParallax.includes(location.pathname);

  const routesWithoutScrollToTopButton = ['/', '/listeCours'];
  const withButton = !routesWithoutScrollToTopButton.includes(location.pathname);

  const sommaireLinkStyle = showParallax ? {} : { marginTop: '47px' };
  const routesWithoutCurrentPath = ['/', '/verification', '/validation_email']; 
  const showCurrentPath = !routesWithoutCurrentPath.includes(location.pathname);

  const pathAliasMap = {
    listeCours: "Programmes",
    formationSaimer: "Cours S'aimer soi",
    formationCroissance: "Cours Croissance",
    formationSecouter: "Cours Écouter la vie en soi",
    profile: "Profile",
    chapitre1: "Chapitre 1",
    chapitre2: "Chapitre 2",
    chapitre3: "Chapitre 3",
    chapitre4: "Chapitre 4",
    chapitre5: "Chapitre 5",
    chapitre6: "Chapitre 6",
    chapitre7: "Chapitre 7",
    chapitre8: "Chapitre 8",
    chapitre9: "Chapitre 9",
    chapitre10: "Chapitre 10",
    chapitre11: "Chapitre 11",
    chapitre12: "Chapitre 12",
    introduction: "Introduction",
    "mentions-legales": "Mentions légales",
    "conditions-generales-de-vente": "Conditions générales de vente",
    "conditions-generales-d-utilisation": "Conditions générales d'utilisation"
  };

  const currentPath = pathSegments
  .map(segment => pathAliasMap[segment] || segment) // Remplace les segments
  .join(" > "); // Construit la chaîne avec le séparateur " > "

  return (
    <>
      {showParallax && <Parallax />}
      
      <animated.header style={navbarStyle} className={showParallax ? "header" : "headerColor"}>
        <nav className="navbar">
          <NavLink to="/" aria-label="Accueil">Let's start</NavLink>
          <NavLink to="/listeCours" aria-label="Liste des cours">Les programmes</NavLink>
          {currentUser ? (
            <>
              <NavLink to="/profile" aria-label="Profil utilisateur">Mon Profil</NavLink>
              <NavButton onClick={handleLogout} aria-label="Déconnexion">Se déconnecter</NavButton>
            </>
          ) : (
            <NavButton onClick={openLoginModal} aria-label="Connexion">Connexion</NavButton>
          )}
        </nav>
      </animated.header>

      {isCoursePage && (
        <Link to={`/${baseSommairePath}`} className="sommaire-nav" style={sommaireLinkStyle}>
          Retour au sommaire
        </Link>
      )}

      {showCurrentPath && (
        <div
          className="current-path-indicator"
          style={{ top: isCoursePage ? '65px' : '25px' }}
        >
          <p>{currentPath}</p>
        </div>
      )}
  
      {withButton && <ScrollToTopButton />}
    </>
  );
};

export default Navbar;