import React, { useState, useRef, useEffect } from 'react';

const AudioPlayer = ({ streamUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // Gestion de lecture/pause
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Gestion du volume
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  // Mise à jour du temps de lecture
  useEffect(() => {
    const audioElement = audioRef.current; // Stocke `audioRef.current` dans une variable locale
  
    const updateTime = () => {
      if (audioElement) {
        setCurrentTime(audioElement.currentTime);
      }
    };
  
    const updateDuration = () => {
      if (audioElement) {
        setDuration(audioElement.duration);
      }
    };
  
    if (audioElement) {
      audioElement.addEventListener('timeupdate', updateTime);
      audioElement.addEventListener('loadedmetadata', updateDuration);
    }
  
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', updateTime);
        audioElement.removeEventListener('loadedmetadata', updateDuration);
      }
    };
  }, []);  

  // Formatte le temps en minutes:secondes
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  // Gestion de la barre de progression
  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <div style={{ width: '80%', backgroundColor: '#FFA500', padding: '10px', borderRadius: '8px', textAlign: 'center' }}>
      <audio ref={audioRef} src={streamUrl} />

      {/* Bouton Lecture/Pause avec une image */}
      <button onClick={togglePlayPause} aria-label={isPlaying ? 'Pause' : 'Lecture'} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <img
          src={isPlaying ? '/pictures/pause.png' : '/pictures/play.png'}
          alt={isPlaying ? 'Pause' : 'Lecture'}
          style={{ width: '8vw', height: 'auto', maxWidth: '50px' }} 
        />
      </button>

      {/* Barre de progression */}
      <div style={{ marginTop: '10px' }}>
        <span>{formatTime(currentTime)} / {formatTime(duration)}</span>
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={(currentTime / duration) * 100 || 0}
        onChange={handleProgressChange}
        style={{ width: '100%', marginTop: '5px' }}
        aria-label="Progression de l'audio"
      />

      {/* Slider de volume avec espace */}
      <label style={{ marginTop: '20px', display: 'block' }}>
        <img
          src="/pictures/volume.png"
          alt="Volume"
          style={{ width: '24px', height: 'auto' }} 
        />
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          style={{ width: '80%', marginTop: '5px', marginLeft: '1%' }}
          aria-label="Volume de l'audio"
        />
      </label>
    </div>
  );
};

export default AudioPlayer;
