import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre10Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 10 : Écouter et vivre l'intuition en soi</h1>

        <p>
          Pas toujours facile de croire en son intuition. Or si j'ai fait ce cours, c'est explicitement pour parler de cela. 
          Accepter ses émotions, être dans une confiance en soi, percevoir son moi profond en s'ouvrant à soi notamment 
          par l'acceptation de sa vulnérabilité (sa force), ou encore gérer sa respiration ou encore son mindset pour profiter 
          au maximum de pouvoir s'écouter au mieux sans avoir de tensions ou d'émotion réprimé… 
          tout ce qu'on a pu voir jusqu'ici est intimement lié au fait d'incarner cette vie qu'on est.
        </p>

        <p>
          <strong>L'authenticité</strong>, c'est l'expression pleine de soi.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Ce que je nomme intuition est à la fois cette <span className="gold">transparence à soi-même</span> et le fait d'être aussi 
            <em>ouvert à quelque chose de plus grand</em>, dans le sens : je ne suis pas vraiment séparé du reste, de ce qu'est la vie. 
            Ainsi, être intuitif résulte de cette capacité à voir cette connexion qu'on a avec le monde. 
            Plus tu es dans l'ego, moins tu peux jouir de l'intuition d'une façon pleine et consciente.
          </p>
        </animated.div>

        <p>
          L'attachement à l'ego va forcément te nuire d'une façon ou d'une autre en te faisant croire des limitations liées à l'insécurité, 
          au pouvoir, à une trop grande fierté, ou toutes autres croyances qui vont être nourries dans cette notion de trop forte individualité.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Imaginons que tu essaies de trouver une solution à une problématique. Est-ce que tu vas plutôt beaucoup réfléchir, 
            faire des plans complexes imaginant toutes les situations périlleuses auxquelles tu pourrais faire face ? 
            Ou, est-ce que tu te poserais, plus dans le silence, un peu comme une méditation ?
          </p>
        </animated.div>

        <p>
          C'est ça la différence entre cet attachement au mental et l'ouverture à l'intuition. 
          On peut être trop habitué à croire qu'à force de penser, cloisonné dans le mental on finira par trouver une solution. Mais c'est faux. 
          Le mental aide, et il aide surtout lorsqu'il est <span className="bold underline">associé à l'ouverture intuitive</span>.
        </p>

        <AnimatedH2 className="subsection-title">Les trois centres de l'intuition</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            L'intuition est liée à trois centres : le centre du ventre, le centre du cœur, et le centre du cerveau. 
            Dît d'une façon plus spirituelle, on parle du chakra sacré, du chakra du cœur, et du chakra du troisième œil, respectivement.
          </p>
        </animated.div>

        <h3 className="technique-title"><strong>Le centre du ventre : l'instinct</strong></h3>

        <p>
          Le ventre est le siège du ressenti - “J'ai été littéralement pris aux <em>tripes</em>” - c'est à partir de ce centre qu'on ressent premièrement. 
          C'est l'instinct, une sorte d'intuition lié au monde physique. Ressentir les choses dans son corps est une première porte de l'intuition dévoilée.
        </p>

        <h3 className="technique-title"><strong>Le centre du cœur : le sentiment</strong></h3>

        <p>
          Le cœur nous ouvre à une nouvelle porte : celle du sentiment - “j'ai senti que cette personne était louche”, 
          “je me sens proche de cette personne alors qu'on se connaît à peine”. C'est le centre de notre connexion aux autres et de ce qui nous touche profondément.
        </p>

        <h3 className="technique-title"><strong>Le centre du cerveau : la perception intérieure</strong></h3>

        <p>
          Le cerveau est le siège de la perception intérieure. C'est ce centre qui nous permet de percevoir plus loin intérieurement, 
          d'avoir des idées qui émergent comme une certitude soudaine. Ici, l'intuition prend la forme de la clarté, comme si tout se mettait en ordre d'un coup.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Souvent, le trajet intuitif va dans ce sens : j'ai une idée → je sens que c'est aligné avec qui je suis → je ressens la joie de le faire. 
            Et on peut voir ainsi que c'est bien les phases de l'intuition du cerveau au ventre en passant par le cœur.
          </p>
        </animated.div>

        <p>
          Quand je parle de “dealer” avec ses émotions dans le titre suivant, c'est une expression que j'utilise pour dire qu'on gère ça comme on peut. 
          Forcément, on n'est pas parfait, et du coup, dealer avec ses émotions, ça signifie que je fais comme je peux dans chaque situation, 
          et que par moment, il en résultera de conséquences plus ou moins négatives comme des tensions physiques par exemple.<br /><br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </div>
  );
}

export default Chapitre10Secouter;
