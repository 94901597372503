import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import TriangleArrowWithIndent from './triangleArrowWithIndent.js';

const onScroll = (set) => (e) => {
  const scrollY = window.scrollY;
  const opacity = Math.max(1 - scrollY / 800, 0);
  set({ scrollY, opacity });
};

function Parallax() {
  const [{ scrollY, opacity }, set] = useSpring(() => ({
    scrollY: 0,
    opacity: 1,
    config: config.gentle,
  }));

  const backgroundRef1 = useRef(null);
  const backgroundRef2 = useRef(null);

  const [textStyle, setTextStyle] = useSpring(() => ({
    from: { opacity: 0 },
    opacity: 1,
    config: { duration: 1000 }
  }));

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleScroll = onScroll(set);
    window.addEventListener('scroll', handleScroll);

    const timeoutId = setTimeout(() => {
      setTextStyle({
        opacity: 0,
        config: { duration: 1800 }, // Durée de 1 seconde pour l'animation de disparition
      });
    }, 6000);

    setIsDarkMode(document.body.classList.contains('dark-mode'));

    // Lazy loading avec Intersection Observer
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: "0px",
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          const imageName = lazyImage.dataset.bg;
          const darkModeSuffix = isDarkMode ? 'nuit' : '';
          lazyImage.style.backgroundImage = `url('${imageName.replace(/\.png$/, `${darkModeSuffix}.png`)}')`;
          observer.unobserve(lazyImage);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (backgroundRef1.current) {
      observer.observe(backgroundRef1.current);
    }
    if (backgroundRef2.current) {
      observer.observe(backgroundRef2.current);
    }

    // MutationObserver pour observer les changements de classe sur le body
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const isDark = document.body.classList.contains('dark-mode');
          setIsDarkMode(isDark);
          // Mettez à jour l'image de fond lorsque le mode change
          if (backgroundRef1.current) {
            const lazyImage = backgroundRef1.current;
            const imageName = lazyImage.dataset.bg;
            const darkModeSuffix = isDark ? 'nuit' : '';
            lazyImage.style.backgroundImage = `url('${imageName.replace(/\.png$/, `${darkModeSuffix}.png`)}')`;
          }
          if (backgroundRef2.current) {
            const lazyImage = backgroundRef2.current;
            const imageName = lazyImage.dataset.bg;
            const darkModeSuffix = isDark ? 'nuit' : '';
            lazyImage.style.backgroundImage = `url('${imageName.replace(/\.png$/, `${darkModeSuffix}.png`)}')`;
          }
        }
      });
    });

    mutationObserver.observe(document.body, { attributes: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, [set, setTextStyle, isDarkMode]);

  return (
    <div className="backgrounds">
      <animated.div
        ref={backgroundRef1}
        className="background-layer1"
        data-bg="/pictures/backgroundLayerBack.png"
        style={{
          transform: scrollY.to((y) => `translateY(-${y / 3}px)`),
        }}
      />
      <animated.div
        ref={backgroundRef2}
        className="background-layer2"
        data-bg="/pictures/backgroundLayerFront.png"
        style={{
          transform: scrollY.to((y) => `translateY(-${y / 7}px)`),
          opacity: opacity.to((o) => o),
        }}
      />
      <animated.h1 style={textStyle} className="beginH1 masked-text">
       <img src="/pictures/logo.png" alt="Logo Flowgraines" className="logo-style" />
       Flowgraines
      </animated.h1>
      <animated.h2 style={textStyle} className="beginH2">
        Cours en ligne pour s'aimer et s'épanouir !
      </animated.h2>
      <TriangleArrowWithIndent />
    </div>
  );
}

export default Parallax;

