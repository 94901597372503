import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import GiphyEmbed from '../animations/giphyEmbed.js'

function Chapitre12Saimer() {
    const [animationStyle1, ref1] = useAnimatedLine();
    const [animationStyle2, ref2] = useAnimatedLine();
    const [animationStyle3, ref3] = useAnimatedLine();

    return (
     <div className="content">

        <h1 className='chapitre'>Chapitre 12: Brille !</h1>
        <p>
        L'image de soi-même qu'on nourrit au fil du temps ne doit pas devenir un conditionnement de vie. Qui suis-je ? Qui veux-je devenir ? <br /><br />
        </p>
        <p style={{ textAlign: 'center' }}>***</p>
        <p>Tant que tu as peur d'une chose comme par exemple l'insécurité financière, tu crées une sorte d'obscurité par rapport à ce sujet. La conséquence est le fait que cette insécurité va pouvoir prendre de la place car tu t'en éloignes et tu lui donnes de la force (de l'énergie, de l'espace). <br /><br /></p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
        <p className="animatable">
        C'est ainsi qu'une peur peut complètement diriger inconsciemment les décisions qu'une personne peut prendre car elle ignore (refoule) ce coin d'ombre. 
        </p>
        </animated.div>

        <p>
        Une solution est de rejoindre cette peur là où elle est. Un peu comme une personne seule que tu rejoins pour lui donner le sentiment de ne pas être délaissée. Plus tu te rapproches de ce qui est délaissé, plus cette peur reviendra comme la conscience d'une chose qui était incomprise et qui n'était pas pleinement acceptée et vécue.<br /><br /><br />
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
        <p className="animatable">
        ***
        </p>
        </animated.div>
        
        <p>
        Si tu crois ou perçois que tu as tel défaut, ce que je te propose c'est de te mettre dans la peau d'un Toi qui a la qualité opposée à ce défaut (une qualité que tu souhaites intégrer), et de te demander : qu'est-ce que le [ton prénom] qui est [la qualité souhaitée] ferait là maintenant ?<br />
        Par exemple, qu'est ce que le [prénom] courageux et discipliné ferait là maintenant ?
        Ne pas hésiter à tout d'abord simplement ressentir ce que cette question produit en toi.
        </p>
        
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
            <p className='gold bold animatable'>
            Si tu veux la lune, ne te cache pas de la nuit.<br /><br />
            Si tu veux une rose, ne fuis pas les épines.<br /><br />
            Si tu veux l'amour, ne te cache pas de toi-même.<br /><br />
            Rûmî
            </p>
        </animated.div>

        <p>
        Continue à briller et à être toi-même, peu importe les opinions ou les intentions des autres. <br /><br />
        Il n'y en a pas d'autres comme toi, alors met tout en œuvre pour t'accepter et rayonner tes qualités propres, celles-là même que tu rayonnes naturellement lorsque tu t'aimes !</p>

        <GiphyEmbed 
          src="https://giphy.com/embed/5Bskpi10b4skzmooNJ"
          title="Flowgraines Fleurs"
          width="480"
          height="480"
        />
        
        <p style={{
            fontSize: '1.3rem',
            textAlign: 'center',
            color: '#E0E0E0',
            fontStyle: 'italic',
            margin: '1em 0'
        }}>"Une fleur ne pense pas à être en compétition avec une autre à côté. Elle fleurit simplement."</p>

        <p><br /><br /><br />
        Souviens-toi toujours que ta Lumière intérieure est précieuse et qu'elle ne doit pas être  gaspillée pour des personnes qui ne sont pas prêtes à la recevoir. Laisse-toi briller et ceux qui sont prêts à grandir et à évoluer avec toi entreront dans ta vie.<br />
        Tu mérites de t'entourer de personnes qui te respectent, t'aiment et t'acceptent pleinement pour qui tu es. <br /><br />
        Je pense profondément que nous sommes là pour vivre heureux et que c'est notre nature d'avoir la direction de notre boussole pointée vers le bonheur, de même qu'on pourrait dire que nous sommes littéralement lui. Il est là, et c'est en débroussaillant ce qui n'est pas le bonheur que nous le retrouvons.<br /><br /><br /><br />
        </p>
        
        <div className="button">
            <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

     </div>
  );
}

export default Chapitre12Saimer;
