import React from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from '../animations/audioPlayer.js';

function Chapitre12Secouter() {
  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 12 : Lever le voile sur la peur pour accéder à son intuition</h1>

        <p className="bold">
          <em>Audio 5 :</em>
        </p>

        {/* Lecteur audio intégré */}
        <AudioPlayer streamUrl="https://firebasestorage.googleapis.com/v0/b/site-web-b50d8.appspot.com/o/audio5.mp3?alt=media&token=72f1c77b-9e7d-4148-b592-4c51e905cd8c" />

        <p><br /><br />
        
        N'oublie pas, si tu sens qu'on n'est pas allé assez loin sur certains sujets, j'ai crée un groupe de partage sur Discord qui est un logiciel de discussion.
        Le lien est disponible en dessous du sommaire.<br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </div>
  );
}

export default Chapitre12Secouter;
