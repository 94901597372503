import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre11Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 11 : Comment se défaire des tensions dues au deal avec nos émotions</h1>

        <p>
          C'est une partie qui a son importance. On fait tous un peu à l'instinct mais parfois savoir plus consciemment 
          comment évacuer des tensions liées à l'émotionnel peut se révéler être très utile. 
          L'émotion peut s'amalgamer ou disons se loger n'importe où au niveau du corps. 
          Il y a des tonnes de pratiques qui visent à rétablir le flux normal du corps mais ici je souhaitais faire du pratico-pratique.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Quand l'énergie devient tension physique, la dénouer est quelque chose d'important pour ne pas laisser cela 
            impacter le reste de sa journée, voire plus.
          </p>
        </animated.div>

        <AnimatedH2 className="subsection-title">Le visage</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Les contractions des muscles du visage sont hyper courantes. Elles sont d'ailleurs reliées à nos pensées et donc à tout ce qui va avec l'anxiété et les peurs.
            Ressentez un instant votre front et détendez-le en fermant vos yeux. Massez vos tempes.
          </p>
        </animated.div>

        <p>
          Prenez le temps pour masser les zones que vous souhaitez détendre de la façon qui vous plaît. 
          Détendez ces zones consciemment.
        </p>

        <AnimatedH2 className="subsection-title">Les épaules</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Les épaules réfèrent souvent à une charge, et la responsabilité qu'on porte. 
            Il est assez courant qu'on puisse avoir du stress qui s'accumule à ce niveau. 
            Il en résulte des épaules crispées, et relevées (la tension peut aussi être dans la nuque).
          </p>
        </animated.div>

        <p>
          Relever volontairement les épaules en inspirant, et détendez-les en les faisant retomber en soufflant doucement. 
          Lorsque vous expirez, faites comme si vous expiriez la tension et le stress accumulés.
        </p>

        <AnimatedH2 className="subsection-title">Articulations coudes, poignets et genoux</AnimatedH2>

        <p>
          Les articulations sont souvent aussi sujettes à cette liaison émotionnelle car ce sont les endroits qui apportent de la mobilité. 
          Symboliquement, elles sont liées à notre flexibilité. Les poignets sont liés à notre capacité dans l'action (peur d'échec…), 
          alors que les coudes sont plus liés à notre capacité de changer de direction (ou manque de flexibilité face à une situation). 
          Les genoux représentent notre capacité à lâcher-prise, à articuler pour avancer. C'est aussi le lien entre JE et NOUS, l'harmonie relationnelle.
          <br />
          <br />
          Bouger ses articulations, faire des étirements, et des exercices de respiration sera bénéfique si vous en sentez le besoin.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Quel que soit la zone, apporter de la conscience à la zone tendue est bénéfique. 
            C'est un acte simple qui peut s'effectuer absolument sans rien faire de spécial, juste en apportant de la conscience à la zone du corps.
          </p>
        </animated.div>

        <p>
          Mais parfois, masser et décontracter physiquement par des mouvements peut aider aussi.
          Vous conviendrez que le sport peut faire partie d'un processus de libération des tensions.
          <br /><br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </div>
  );
}

export default Chapitre11Secouter;
