import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StripeCheckout from './StripeCheckout.js';
import { db } from './donnees/firebase.js';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useAuth } from './AuthContext.js';
import { programmes, ensembles, courseToBundleMap } from './metaprogrammes.js';

const ListeCours = () => {
  const navigate = useNavigate();

  const { currentUser, userData, loadUserData, updateUserCoursAchetes, updateTitle } = useAuth();
  const [idProgrammeItem, setIdProgrammeItem] = useState('');
  const [userConnected, setUserConnected] = useState(null);

  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (currentUser && !userData) {
      loadUserData(currentUser.uid);
    }
    setUserConnected(currentUser && userData?.emailVerified);
  }, [currentUser, userData, loadUserData]);
  
  useEffect(() => {
    updateTitle("Flowgraines - Programmes");
  }, []);

  const handleClick = (path, programmeId) => {
    const isCoursePurchased = userData?.coursAchetes?.includes(courseToBundleMap[programmeId]);
    setIdProgrammeItem(programmeId);

    if (userConnected && isCoursePurchased) 
    {
      navigate(path);
    } else {
      setShowAnimation(true); // Animation si pas connecté  
      setTimeout(() => setShowAnimation(false), 400);
    }
  };

  const handlePayment = (programmeId) => {
    const bundleId = courseToBundleMap[programmeId];
    const selectedBundle = ensembles.find(bundle => bundle.id === bundleId);

    if (selectedBundle) {
      setSelectedProgramme(selectedBundle);
      setShowCheckout(true);
    } else {
      console.error("Aucun bundle associé pour ce cours.");
    }
  };

  const addCoursAchete = async (courseId) => {
    // Référence au document de l'utilisateur dans la collection 'utilisateurs'
    const userRef = doc(db, 'utilisateurs', currentUser.uid);
  
    try {
      // Mise à jour du document utilisateur avec le nouveau cours acheté
      await updateDoc(userRef, {
        coursAchetes: arrayUnion(courseId)
      });
      updateUserCoursAchetes(courseId);
      console.log('Cours ajouté avec succès.');

    } catch (error) {
      console.error('Erreur lors de l\'ajout du cours acheté:', error);
      alert(`Erreur lors de l'ajout: ${error.message}`);
    }
  };

  const handleClose = () => {
    setShowCheckout(false);
  };

  return (
    <div className="content">
      <div className='listeCours_p'>
        {currentUser ? (
            <p>
              Bienvenue <span style={{ color: '#4a90e2' }}>{userData?.prenom ? userData.prenom : ''}</span>, découvrez les cours Flowgraines et faites fleurir votre potentiel ! Ils vous guideront pour dépasser vos croyances limitantes, vous ouvrir à l'amour et maîtriser votre monde intérieur. Prêt à faire éclore votre véritable potentiel ?<br />
            </p>
            ) : (
            <p>
              Bienvenue, ici on appelle nos programmes, les flowgraines. Des cours animés, des graines, qui nous guident vers le flow ! <br />
            </p>
        )}
      
        {currentUser && !userConnected && (
          <p>Veuillez vérifier votre adresse e-mail</p>
        )}
        
        {!currentUser&& <p className="fonce">Accédez à ces cours passionnants en vous connectant !</p>}
      </div>

      <div className="programmes-list">

        {programmes.map((programme) => (
          
          <div key={programme.id} 
          className={`programme-item ${idProgrammeItem === programme.id && showAnimation ? "animate" : ""}`} 
          onClick={() => handleClick(programme.path, programme.id)}>
            <h1>{programme.title}</h1>
            {programme.description.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
            {userConnected && ( 
              userData?.coursAchetes?.includes(courseToBundleMap[programme.id]) ? (
                <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
                  Accès au cours
                </div>
              ) : (
                <button className="get-button" onClick={(e) => {
                  e.stopPropagation();
                  handlePayment(programme.id); 
                }}>
                  Obtenir le cours 
                </button>
              )
            )}
          </div>
        ))}

        <div className="programme-item" onClick={() => navigate('/formationCroissance')}>
          <h1>Flowgraine Notre croissance personnelle</h1>
          <p>Évoluer au quotidien est un défi qui demande patience et persévérance. Plutôt que de chercher uniquement à "gagner", apprenons à adopter une attitude de fidélité envers nous-mêmes, faite de résilience et d'ouverture, pour faire face aux épreuves que la vie nous apporte. <br />
          <em style={{ fontSize: '0.9em' }}>Disponible pour tous.</em>
          </p>
          <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
            Accès au cours
          </div>
        </div>

        <a href="https://www.instagram.com/oretlien_art/" rel="noreferrer noopener" target="_blank">🎨 Suivez-moi sur Instagram !</a>

        <>
        {showCheckout && currentUser ? (
          <StripeCheckout
            onSuccessfulCheckout={() => {
              addCoursAchete(selectedProgramme.id);
              setShowCheckout(false);
            }}
            programme={selectedProgramme}
            onClose={handleClose}
          />
        ) : (
            <p></p>
        )}
        </> 
      </div>

    </div>  
  );
};

export default ListeCours;
